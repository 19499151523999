import React, { useState, useEffect } from 'react';
import certo from '../../assets/certo.png';
import errado from '../../assets/errado.png';
import api from '../../services/api';
import './style.css';
export default function Products({ history }) {
  const [ increment, setIncrement ] = useState(0);
  const [ acertou, setAcertou ] = useState(0);
  const [ errou, setErrou ] = useState(0);
  const [ products, setProducts ] = useState([]);
  const [ trocar, setTrocar ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const myRef = React.createRef();
  const myRefButton1 = React.createRef();
  const myRefButton2 = React.createRef();
  useEffect(() => {
    (async function loadProducts() {
        try {
          const response = await api.get('/api/products/random')
          localStorage.setItem('data', JSON.stringify(response.data))
          setProducts(JSON.parse(localStorage.getItem('data')))
          setLoading(false);
        }catch(e) {
          history.push('/')
        }
      })()
  }, [])
  useEffect(() => {
    myRefButton1.current.disabled = false;
    myRefButton2.current.disabled = false;
    if(products.length !== 0) {
      if(increment === products.length) {
        history.push(`/Result/${acertou}/${errou}`)
      }
    }
  }, [increment])
  useEffect(() => {
   myRef.current.addEventListener('animationend', function (event) {
     setTrocar(!trocar)
   })
  }, [trocar])
  function acerto() {
    myRefButton1.current.disabled = true;
    setAcertou(acertou+1);
    setTrocar(!trocar);
    if(increment < products.length) {
      setTimeout(() => {
        setIncrement(increment+1);
      }, 300)
    }
  }
  function erro() {
    myRefButton2.current.disabled = true;
    setErrou(errou+1);
    setTrocar(!trocar);
    if(increment < products.length) {
      setTimeout(() => {
        setIncrement(increment+1);
      }, 300)
    }
  }
  return (
    <section className="products" >
      <div className={trocar ? 'product trocar': 'product'} ref={myRef}>
      {loading ? (
                <></>
              ) : (
                <>
                {increment < products.length ? (
                  <>
                    { products.map((product, index) => (
                      <div key={index}>
                      { index === increment ? (
                        <>
                          <div className="product__image">
                            <img src={require('../../assets/'+product.imageUrl)} alt={product.title}/>
                          </div>
                          <p>{product.title}</p>
                        </>
                      ) :
                      (<div className="product__image" className="display">
                          <img src={require('../../assets/'+product.imageUrl)} alt={product.title}/>
                          <p>{product.title}</p>
                      </div>)}
                    </div>
                    ))}
                    </>
                    ) : ""}
                </>
              )}
      </div>
      <div className="products__button">
        <label htmlFor="errado">
          <img src={errado} alt="Errado"  />
        </label>
        <label htmlFor="certo">
          <img src={certo} alt="Certo"  />
        </label>
        <button onClick={acerto} id="certo" ref={myRefButton1} />
        <button onClick={erro}  id="errado" ref={myRefButton2} />
      </div>
    </section>
  );
}
