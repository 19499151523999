import React from 'react';
import logo from '../../assets/ponto.png';
import './style.css';
export default function Main({ history }) {
  function handleTouch() {
    history.push('/Products')
  }
  return (
    <section className="main" onClick={handleTouch}>
      <div className="main__ponto">
        <img src={logo} alt="Ponto de interrogação"/>
      </div>
      <div className="main__description">
        <p>ADIVINHE O QUE</p>
      </div>
      <div className="main__subdescription">
        <p>#tem</p>
        <span>no</span>
        <p>MAGALU</p>
      </div>
    </section>
  );
}
