import React from 'react';
import logo from '../../assets/logo_Magalu.png';
import './style.css';
import { Link } from "react-router-dom";
export default function Header() {
  return (
    <header className="header">
        <div className="header__container">
            <div className="header__bars">
                <i className="fa fa-bars"></i>
            </div>
            <div className="header__logo">
                <Link to="/">
                    <img src={logo} alt="Magalu"/>
                </Link>
            </div>
        </div>
        <div className="header__icons">
            <i className="fa fa-search"></i>
            <i className="fa fa-shopping-cart"></i>
        </div>
    </header>
  );
}
