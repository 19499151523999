import React, { useEffect } from 'react';

import './style.css';

export default function Result({ history, match }) {
  useEffect(() => {
    setTimeout(() => {
      history.push('/')
    }, 5000);
  }, [history])

  return (
    <section className="result">
    <div className="result__title">
      <p>{ match.params.acertos >= match.params.erros ? 'PARABÉNS!' : ':('}</p>
    </div>
    <div className="result__acertos">
      <p>{ match.params.acertos } <br/> Acertos </p>
    </div>
    <div className="result__erros">
      <p>{ match.params.erros } <br/> Erros </p>
    </div>
    </section>
  );
}
