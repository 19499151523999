import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Main from './pages/Main'
import Products from './pages/Products'
import Result from './pages/Result'
import Header from './components/Header'

export default function Routes() {
    return (
        <BrowserRouter>
	    <Header />
            <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/Products" component={Products} />
                <Route path="/Result/:acertos/:erros" component={Result} />
            </Switch>
        </BrowserRouter>
    )
}
